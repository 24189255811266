<template>
  <div class="mb-10">
    <!--begin::Heading-->
    <h2 class="anchor fw-bolder mb-5">
      <a href="#grouping-table-head"></a>
      Grouping table head
    </h2>
    <!--end::Heading-->

    <!--begin::Block-->
    <div class="py-5">
      When the data structure is complex, you can use group header to show the
      data hierarchy.
    </div>
    <!--end::Block-->

    <div class="rounded border p-10">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="date" label="Date" width="150">
        </el-table-column>
        <el-table-column label="Delivery Info">
          <el-table-column prop="name" label="Name" width="120">
          </el-table-column>
          <el-table-column label="Address Info">
            <el-table-column prop="state" label="State" width="120">
            </el-table-column>
            <el-table-column prop="city" label="City" width="120">
            </el-table-column>
            <el-table-column prop="address" label="Address"> </el-table-column>
            <el-table-column prop="zip" label="Zip" width="120">
            </el-table-column>
          </el-table-column>
        </el-table-column>
      </el-table>

      <CodeHighlighter :field-height="400" lang="html">{{
        code9
      }}</CodeHighlighter>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter";
import { code9 } from "./data.ts";

export default defineComponent({
  name: "grouping-table-head",
  data() {
    return {
      tableData: [
        {
          date: "2016-05-03",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036"
        },
        {
          date: "2016-05-02",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036"
        },
        {
          date: "2016-05-04",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036"
        },
        {
          date: "2016-05-01",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036"
        },
        {
          date: "2016-05-08",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036"
        },
        {
          date: "2016-05-06",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036"
        },
        {
          date: "2016-05-07",
          name: "Tom",
          state: "California",
          city: "Los Angeles",
          address: "No. 189, Grove St, Los Angeles",
          zip: "CA 90036"
        }
      ]
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code9
    };
  }
});
</script>
